<template>
  <v-card>
    <v-card-title class="text-h6">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-switch
            v-on="on"
            :input-value="iconShortcuts"
            class="mt-0"
            hide-details
            @change="updateSetting({ key: 'iconShortcuts', value: $event })"
          />
        </template>
        <span>
          {{ $t('profile.controls.show_only_icons') }}
        </span>
      </v-tooltip>
      {{ $t('profile.general.shortcuts') }}
    </v-card-title>

    <ShortcutTable
      :items="shortcuts"
      :loading="$store.getters.loading['get:api/shortcut']"
      @delete="deleteShortcut"
      @click:row="$router.push({ name: 'editShortcut', params: { shortcutId: $event.id } })"
    />

    <v-card-actions>
      <v-spacer />
      <v-btn color="primary" text @click="$router.push({ name: 'createShortcut' })">
        {{ $t('profile.controls.create_new') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ShortcutTable from '@/components/tables/ShortcutTable';

export default {
  name: 'Shortcuts',

  components: { ShortcutTable },

  computed: {
    ...mapState('settings', ['iconShortcuts']),
    ...mapState('shortcuts', ['shortcuts']),
  },

  methods: {
    ...mapActions('settings', ['updateSetting']),
    ...mapActions('shortcuts', ['deleteShortcut']),
  },
};
</script>

<style scoped></style>
