var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{class:{ 'content-loading': _vm.loading },attrs:{"expanded":_vm.tableMixin_expandedRows,"headers":_vm.tableMixin_displayedHeaders,"items":_vm.items,"mobile-breakpoint":0,"loading":_vm.loading,"item-class":function () { return 'clickable'; },"disable-sort":"","disable-pagination":"","hide-default-footer":""},on:{"click:row":_vm.tableMixin_onRowClick},scopedSlots:_vm._u([{key:"item.icon",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(item.icon))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('BaseActionMenu',{attrs:{"actions":_vm.getRowActions(item),"loading":_vm.$store.getters.loading[("delete:api/shortcut/" + (item.id))],"item":item}})]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('BaseExpandedTableRow',{attrs:{"colspan":_vm.tableMixin_displayedHeaders.length,"headers":_vm.tableMixin_hiddenHeaders,"item":item},scopedSlots:_vm._u([{key:"item.icon",fn:function(){return [_c('v-icon',[_vm._v(_vm._s(item.icon))])]},proxy:true}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }