<template>
  <v-data-table
    :expanded="tableMixin_expandedRows"
    :headers="tableMixin_displayedHeaders"
    :items="items"
    :mobile-breakpoint="0"
    :class="{ 'content-loading': loading }"
    :loading="loading"
    :item-class="() => 'clickable'"
    disable-sort
    disable-pagination
    hide-default-footer
    @click:row="tableMixin_onRowClick"
  >
    <template v-slot:item.icon="{ item }">
      <v-icon>{{ item.icon }}</v-icon>
    </template>

    <template v-slot:item.actions="{ item }">
      <BaseActionMenu
        :actions="getRowActions(item)"
        :loading="$store.getters.loading[`delete:api/shortcut/${item.id}`]"
        :item="item"
      />
    </template>

    <template v-slot:expanded-item="{ headers, item }">
      <BaseExpandedTableRow
        :colspan="tableMixin_displayedHeaders.length"
        :headers="tableMixin_hiddenHeaders"
        :item="item"
      >
        <template v-slot:item.icon>
          <v-icon>{{ item.icon }}</v-icon>
        </template>
      </BaseExpandedTableRow>
    </template>
  </v-data-table>
</template>

<script>
import BaseActionMenu from '@/components/base/BaseActionMenu';
import BaseExpandedTableRow from '@/components/base/BaseExpandedTableRow';
import tableMixin from '@/mixins/table-mixin';

export default {
  name: 'ShortcutTable',

  components: {
    BaseExpandedTableRow,
    BaseActionMenu,
  },

  mixins: [tableMixin],

  props: {
    items: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    headers() {
      return [
        {
          text: this.$t('general.title'),
          value: 'title',
        },
        {
          text: this.$t('general.url'),
          value: 'link',
          hidden: 'xsOnly',
        },
        {
          text: this.$t('general.icon'),
          value: 'icon',
          hidden: 'xsOnly',
        },
        {
          value: 'actions',
        },
      ];
    },
  },

  methods: {
    getRowActions() {
      const actions = [];

      if (this.tableMixin_hiddenHeaders.length) {
        actions.push({
          callback: (p) => this.$emit('click:row', p),
          label: this.$t('general.controls.edit'),
          icon: 'edit',
        });
      }

      if (this.$isAdmin()) {
        actions.push({
          callback: (p) => this.$emit('delete', p),
          label: this.$t('general.controls.delete'),
          icon: 'delete',
        });
      }

      return actions;
    },
  },
};
</script>
