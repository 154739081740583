<template>
  <v-form @submit.prevent="changePassword">
    <v-card>
      <v-card-title>
        {{ $t('profile.general.change_password') }}
      </v-card-title>

      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="passwords.current_password"
              :error-messages="errors.current_password"
              :append-icon="hideCurrent ? 'visibility' : 'visibility_off'"
              :type="hideCurrent ? 'password' : 'text'"
              :label="$t('profile.labels.current_password')"
              @click:append="hideCurrent = !hideCurrent"
              @input="clearErrors('current_password')"
            />
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="passwords.new_password"
              :error-messages="errors.new_password"
              :append-icon="hideNew ? 'visibility' : 'visibility_off'"
              :type="hideNew ? 'password' : 'text'"
              :label="$t('general.modal_titles.new_password')"
              @click:append="hideNew = !hideNew"
              @blur="clearErrors('new_password')"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          :disabled="loading['put:api/current-user/password']"
          :loading="loading['put:api/current-user/password']"
          color="primary"
          type="submit"
          text
        >
          {{ $t('general.controls.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { openSnackbar } from '@/util/event-bus';
import userService from '@/api/user-service';
import { mapGetters } from 'vuex';
import { mapErrorsToInputs } from '@/util/forms';

export default {
  name: 'CurrentUserPasswordChangeForm',

  data() {
    return {
      hideNew: true,
      hideCurrent: true,
      passwords: {
        current_password: '',
        new_password: '',
      },
      errors: {},
    };
  },

  computed: {
    ...mapGetters(['loading']),
  },

  methods: {
    clearErrors(key) {
      if (this.errors[key]) {
        delete this.errors[key];
      }
    },

    async changePassword() {
      try {
        await userService.changeCurrentUserPassword(this.passwords);
        openSnackbar(this.$t('profile.general.password_changed'));
        this.passwords.current_password = '';
        this.passwords.new_password = '';
      } catch (e) {
        this.errors = mapErrorsToInputs(e);
      }
    },
  },
};
</script>
