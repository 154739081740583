<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="12" md="5" class="pr-0 pr-md-3">
        <Settings class="mb-3" />
        <CurrentUserPasswordChangeForm class="mb-3" />
      </v-col>

      <v-col v-if="$can(['app.shortcuts.*'])" cols="12" md="7">
        <Shortcuts />
      </v-col>
    </v-row>

    <router-view />
  </v-container>
</template>

<script>
import CurrentUserPasswordChangeForm from '../components/forms/CurrentUserPasswordChangeForm.vue';
import Settings from '../components/Settings.vue';
import Shortcuts from '@/views/shortcuts/Shortcuts';

export default {
  name: 'Profile',

  components: {
    CurrentUserPasswordChangeForm,
    Shortcuts,
    Settings,
  },
};
</script>
