<template>
  <v-card>
    <v-card-title>
      {{ $t('general.settings') }}
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col v-if="$can(['app.settings.dark_theme.*'])" cols="6">
          <v-switch
            :input-value="darkTheme"
            class="ma-0"
            :label="$t('profile.labels.eye_saving_mode')"
            hide-details
            @change="onThemeChange"
          />
        </v-col>

        <v-col cols="6" style="position: relative; right: 12px">
          <LanguageSelector
            :selected-locale="locale"
            :label="$t('general.locale.language')"
            @change="onLocaleChange"
          />
        </v-col>

        <template v-if="$can(['app.settings.home_page.*'])">
          <v-col cols="12">
            <v-subheader class="px-0 pb-0 pt-2">
              {{ $t('profile.general.home_page') }}
            </v-subheader>
          </v-col>

          <v-col cols="6">
            <v-switch
              :input-value="urgentUserStories"
              :label="$t('general.urgent_user_stories')"
              class="mt-0"
              hide-details
              @change="onSettingChange('urgentUserStories', $event)"
            />
          </v-col>

          <v-col cols="6">
            <v-switch
              :input-value="ongoingSprints"
              class="ma-0"
              :label="$t('general.ongoing_sprints')"
              hide-details
              @change="onSettingChange('ongoingSprints', $event)"
            />
          </v-col>

          <v-col cols="6">
            <v-switch
              :input-value="newIssues"
              class="ma-0"
              :label="$t('profile.labels.new_issues')"
              hide-details
              @change="onSettingChange('newIssues', $event)"
            />
          </v-col>
        </template>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import LanguageSelector from '@/components/LanguageSelector';

export default {
  name: 'Settings',

  components: {
    LanguageSelector,
  },

  computed: {
    ...mapState('settings', [
      'locale',
      'darkTheme',
      'urgentUserStories',
      'ongoingSprints',
      'newIssues',
    ]),
  },

  methods: {
    ...mapMutations('settings', ['UPDATE_APPLICATION_SETTINGS', 'UPDATE_LOCALE']),
    ...mapActions('settings', ['updateSetting']),

    onLocaleChange(locale) {
      this.UPDATE_LOCALE(locale);
      this.$vuetify.lang.current = locale;
    },

    onSettingChange(key, value) {
      this.updateSetting({
        key,
        value,
      });
    },

    onThemeChange(value) {
      this.updateSetting({
        key: 'darkTheme',
        value,
      });
      this.$vuetify.theme.dark = value;
    },
  },
};
</script>
